import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Box,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getGlobalSettings, deleteGlobalSettings } from '../services/globalSettingsService';
import DashboardLayout from '../components/DashboardLayout';

export default function GlobalSettingsList() {
  const [globalSettings, setGlobalSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchGlobalSettings();
  }, []);

  const fetchGlobalSettings = async () => {
    try {
      const data = await getGlobalSettings();
      setGlobalSettings(data);
    } catch (error) {
      console.error('Error fetching global settings:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setDeleteId(null);
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteGlobalSettings(deleteId);
      fetchGlobalSettings();
    } catch (error) {
      console.error('Error deleting global setting:', error);
    } finally {
      handleCloseDialog();
    }
  };

  return (
    <DashboardLayout>
      <Container>
        <Box mt={4}>
          <Typography variant="h4" gutterBottom>
            Global Settings
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/global-settings/create')}
            sx={{ marginBottom: 2 }}
          >
            Add New Global Setting
          </Button>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>OG Title</TableCell>
                    <TableCell>OG Description</TableCell>
                    <TableCell>OG Image</TableCell>
                    <TableCell>Twitter Title</TableCell>
                    <TableCell>Twitter Description</TableCell>
                    <TableCell>Twitter Image</TableCell>
                    <TableCell>Favicon URL</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {globalSettings.map((setting) => (
                    <TableRow key={setting.id}>
                      <TableCell>{setting.title}</TableCell>
                      <TableCell>{setting.description}</TableCell>
                      <TableCell>{setting.ogTitle}</TableCell>
                      <TableCell>{setting.ogDescription}</TableCell>
                      <TableCell>{setting.ogImage}</TableCell>
                      <TableCell>{setting.twitterTitle}</TableCell>
                      <TableCell>{setting.twitterDescription}</TableCell>
                      <TableCell>{setting.twitterImage}</TableCell>
                      <TableCell>{setting.faviconUrl}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          onClick={() => navigate(`/global-settings/edit/${setting.id}`)}
                          sx={{ marginRight: 1 }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenDialog(setting.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Box>

        {/* Confirmation Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this global setting?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </DashboardLayout>
  );
}
