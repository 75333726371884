import React, { createContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null); // Stores the authenticated user's information
  const navigate = useNavigate();

  // Log in the user by decoding the token and storing it
  const login = (token) => {
    try {
      const decoded = jwtDecode(token);
      const expirationTime = decoded.exp * 1000; // Convert `exp` from seconds to milliseconds

      if (Date.now() > expirationTime) {
        throw new Error('Token is already expired.');
      }

      localStorage.setItem('token', token);
      localStorage.setItem('tokenExpiration', expirationTime.toString());

      setUser(decoded); // Set user state to the decoded token data
    } catch (error) {
      console.error('Error decoding token:', error);
      logout(); // Clear everything and navigate to login
    }
  };

  // Log out the user by clearing the token and resetting the state
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    setUser(null);
    navigate('/login');
  };

  // Check if the token is expired and log out if it is
  const checkTokenExpiration = () => {
    const expirationTime = localStorage.getItem('tokenExpiration');
    if (expirationTime && Date.now() > Number(expirationTime)) {
      console.warn('Token expired. Logging out.');
      logout();
    }
  };

  // Initialize the authentication state when the app loads
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const expirationTime = decoded.exp * 1000; // Convert `exp` from seconds to milliseconds

        if (Date.now() < expirationTime) {
          setUser(decoded); // Token is valid, set user state
        } else {
          console.warn('Token expired. Logging out.');
          logout();
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        logout(); // Clear invalid token
      }
    }

    // Periodically check for token expiration (every minute)
    const interval = setInterval(checkTokenExpiration, 60 * 1000);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}