import api from './api';

// Retrieve all global settings
export const getGlobalSettings = async () => {
  try {
    const response = await api.get('/GlobalSettings');
    return response.data || [];
  } catch (error) {
    console.error('❌ Error in getGlobalSettings:', error);
    throw error;
  }
};

// Retrieve global settings by ID
export const getGlobalSettingsById = async (id) => {
  try {
    const response = await api.get(`/GlobalSettings/${id}`);
    return response.data;
  } catch (error) {
    console.error('❌ Error in getGlobalSettingsById:', error);
    throw error;
  }
};

// Retrieve global settings by Page Slug
export const getGlobalSettingsBySlug = async (slug) => {
  try {
    const response = await api.get(`/GlobalSettings/page/${slug}`);
    return response.data;
  } catch (error) {
    console.error(`❌ Error in getGlobalSettingsBySlug (Slug: ${slug}):`, error);
    throw error;
  }
};

// Create a new global setting
export const createGlobalSettings = async (globalSettingsData) => {
  try {
    const response = await api.post('/GlobalSettings', globalSettingsData);
    return response.data;
  } catch (error) {
    console.error('❌ Error in createGlobalSettings:', error);
    throw error;
  }
};

// Update an existing global setting by ID
export const updateGlobalSettings = async (id, globalSettingsData) => {
  try {
    await api.put(`/GlobalSettings/${id}`, globalSettingsData);
  } catch (error) {
    console.error('❌ Error in updateGlobalSettings:', error);
    throw error;
  }
};

// Delete a global setting by ID
export const deleteGlobalSettings = async (id) => {
  try {
    await api.delete(`/GlobalSettings/${id}`);
  } catch (error) {
    console.error('❌ Error in deleteGlobalSettings:', error);
    throw error;
  }
};
