import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  Drawer,
} from '@mui/material';
import {
  Assignment as AssignmentIcon,
  Book as BookIcon,
  Build as BuildIcon,
  Business as BusinessIcon,
  ContactMail as ContactMailIcon,
  ExitToApp as ExitToAppIcon,
  Group as GroupIcon,
  Home as HomeIcon,
  People as PeopleIcon,
  Work as WorkIcon,
  Person as PersonIcon,
  Article as ArticleIcon,
  Settings as SettingsIcon,
  Category as CategoryIcon,
  Menu as MenuIcon, // Added this import
} from '@mui/icons-material';
import { AuthContext } from '../contexts/AuthContext';
import logo from '../assets/logov2.png';

const drawerWidth = 240;

function DashboardLayout(props) {
  const { window, children } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(!isMobile);

  const { logout, user } = useContext(AuthContext); // Assume `user` contains role info

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Admin Panel
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {/* Common items accessible by all authorized users */}
        <ListItem button onClick={() => navigate('/')} key="Dashboard">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button onClick={() => navigate('/blogs')} key="Blogs">
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Blogs" />
        </ListItem>
        <ListItem button onClick={() => navigate('/categories')} key="Categories">
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>
        <ListItem button onClick={() => navigate('/global-settings')} key="Global Settings">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Global Settings" />
        </ListItem>
        <ListItem button onClick={() => navigate('/products')} key="Products">
          <ListItemIcon>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>

        {/* Accessible by Admins and Editors */}
        {(user.role === 'Admin' || user.role === 'Editor') && (
          <ListItem button onClick={() => navigate('/contacts')} key="Contacts">
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <ListItemText primary="Contacts" />
          </ListItem>
        )}

        {/* Accessible by Admins only */}
        {user.role === 'Admin' && (
          <ListItem button onClick={() => navigate('/users')} key="Users">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        )}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: '#00274D',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="MeGuide Consulting Logo" style={{ height: 70, marginRight: 8 }} />
          </Box>
          <Tooltip title="Logout">
            <IconButton color="inherit" onClick={logout}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? drawerWidth : theme.spacing(7),
            boxSizing: 'border-box',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            '&:hover': {
              width: drawerWidth,
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          },
        }}
      >
        {drawer}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: theme.spacing(3),
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: open ? `${drawerWidth}px` : theme.spacing(7),
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

DashboardLayout.propTypes = {
  window: PropTypes.func,
  children: PropTypes.node,
};

export default DashboardLayout;
