import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, MenuItem } from '@mui/material';
import { createGlobalSettings, updateGlobalSettings, getGlobalSettingsById } from '../services/globalSettingsService';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Define Pages List (Predefined Page Slugs)
const pageOptions = [
  { label: 'Home', value: 'home' },
  { label: 'About', value: 'about' },
  { label: 'Blogs (Default for All Posts)', value: 'blogs' },
  { label: 'Contact', value: 'contact' },
  { label: 'Products', value: 'products' },
];

function GlobalSettingsForm() {
  const [initialValues, setInitialValues] = useState({
    pageSlug: '',
    title: '',
    description: '',
    ogTitle: '',
    ogDescription: '',
    ogImage: '',
    twitterTitle: '',
    twitterDescription: '',
    twitterImage: '',
    faviconUrl: '',
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchGlobalSetting(id);
    }
  }, [id]);

  const fetchGlobalSetting = async (id) => {
    try {
      const data = await getGlobalSettingsById(id);
      setInitialValues(data);
    } catch (error) {
      console.error('Error fetching global setting:', error);
    }
  };

  const GlobalSettingsSchema = Yup.object().shape({
    pageSlug: Yup.string().required('Page is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    ogTitle: Yup.string(),
    ogDescription: Yup.string(),
    ogImage: Yup.string(),
    twitterTitle: Yup.string(),
    twitterDescription: Yup.string(),
    twitterImage: Yup.string(),
    faviconUrl: Yup.string(),
  });

  const handleSubmit = async (values) => {
    try {
      if (id) {
        await updateGlobalSettings(id, values);
      } else {
        await createGlobalSettings(values);
      }
      navigate('/global-settings');
    } catch (error) {
      console.error('Error saving global setting:', error);
    }
  };

  return (
    <DashboardLayout>
      <Container maxWidth="sm">
        <Box mt={4}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={GlobalSettingsSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                {/* Select Page */}
                <TextField
                  select
                  label="Page"
                  name="pageSlug"
                  value={values.pageSlug}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  error={touched.pageSlug && Boolean(errors.pageSlug)}
                  helperText={touched.pageSlug && errors.pageSlug}
                >
                  {pageOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField label="Title" name="title" value={values.title} onChange={handleChange} variant="outlined" margin="normal" fullWidth required />
                <TextField label="Description" name="description" value={values.description} onChange={handleChange} variant="outlined" margin="normal" fullWidth required />
                <TextField label="OG Title" name="ogTitle" value={values.ogTitle} onChange={handleChange} variant="outlined" margin="normal" fullWidth />
                <TextField label="OG Description" name="ogDescription" value={values.ogDescription} onChange={handleChange} variant="outlined" margin="normal" fullWidth />
                <TextField label="OG Image" name="ogImage" value={values.ogImage} onChange={handleChange} variant="outlined" margin="normal" fullWidth />
                <TextField label="Twitter Title" name="twitterTitle" value={values.twitterTitle} onChange={handleChange} variant="outlined" margin="normal" fullWidth />
                <TextField label="Twitter Description" name="twitterDescription" value={values.twitterDescription} onChange={handleChange} variant="outlined" margin="normal" fullWidth />
                <TextField label="Twitter Image" name="twitterImage" value={values.twitterImage} onChange={handleChange} variant="outlined" margin="normal" fullWidth />
                <TextField label="Favicon URL" name="faviconUrl" value={values.faviconUrl} onChange={handleChange} variant="outlined" margin="normal" fullWidth />

                <Box mt={2}>
                  <Button type="submit" variant="contained" color="primary">
                    {id ? 'Update Setting' : 'Create Setting'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </DashboardLayout>
  );
}

export default GlobalSettingsForm;
